define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["en-us", {
    "common": {
      "$": "$",
      "change": "Change Client",
      "invoice": "Invoice",
      "myClient": "My Client",
      "myManager": "My Client Manager",
      "payment": "Payment",
      "pending": "Pending",
      "pendingPayments": "Pending Payments",
      "privatePolicy": "Private Policy",
      "save": "Save",
      "security": "Security",
      "select": "Select",
      "unableToLoadResources": "Unable to load {modelName}",
      "unknownError": "An unknown error occurred",
      "updatedSuccessfully": "Successfully Updated",
      "viewAll": "View All",
      "zencase": "ZenCase"
    },
    "dashboard": {
      "welcome": "Welcome"
    },
    "empty": {
      "blurb": "Check back later for updates.",
      "title": "You don't have any {modelName} yet."
    },
    "forgotPassword": {
      "blurb": "To reset your password enter your email.",
      "error": "Your password could not be reset.",
      "forgotPassword": "Forgot your password",
      "forgotPasswordQuestion": "Forgot your password?",
      "reset": "Your password has been reset.",
      "resetBlurb": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "resetPassword": "Reset Password",
      "resetPasswordBlurb": "Please enter a new password.",
      "resetPasswordToken": "Reset password token is invalid"
    },
    "invoice": {
      "actions": {
        "openingInvoice": "Opening Invoice...",
        "pay": "Pay Now",
        "preview": "View Invoice",
        "previewError": "Unable to open invoice"
      },
      "dueDate": "Due Date",
      "invoice": "Invoice",
      "invoicedDate": "Invoiced Date",
      "recentClosedInvoices": "Recent Closed Invoices",
      "recentOpenInvoices": "Recent Open Invoices",
      "status": "Status"
    },
    "login": {
      "blurb": "By using ZenCase you agree to the ",
      "errors": {
        "accountLocked": "Your account has been locked due to too many invalid attempts. Please check your email for unlock instructions.",
        "invalidAuthyCode": "Authentication Code was invalid, please try again or request a new code.",
        "invalidCredentials": "Your email or password was invalid."
      },
      "password": "Password",
      "signIn": "Sign In",
      "signInToContinue": "Sign in to continue to ZenCase.",
      "verify": "Authenticate Your Account ",
      "verifySignIn": "Please enter the access code we sent to you.",
      "welcomeBack": "Welcome Back!"
    },
    "logout": {
      "loggingOut": "Logging Out",
      "loggingOutBlurb": "You are currently being logged out."
    },
    "navigation": {
      "dashboard": "Dashboard",
      "navigation": "Navigation"
    },
    "notFound": {
      "blurb": "Sorry, page not found",
      "goToDashboard": "Back to Dashboard",
      "title": "404"
    },
    "payment": {
      "recentPayments": "Recent Payments"
    },
    "popup": {
      "error": "Please disable pop up blocker in your browser settings in order to open this file"
    },
    "select": {
      "blurb": "You have access to multiple clients. Please select the client you wish to access."
    },
    "signUp": {
      "blurb": "By registering you agree to the ",
      "confirmPassword": "Confirm Password",
      "registerAccount": "Register Account",
      "registerAccountBlurb": "Register to get your ZenCase account now.",
      "signUp": "Register",
      "termsOfUse": "Terms of Use",
      "welcomeToZenCase": "Welcome to ZenCase"
    },
    "unlock": {
      "blurb": "To resend unlock instructions enter your email.",
      "error": "Something went wrong when unlocking your account. Please try again later.",
      "help": "Didn't Receive Unlock Instructions?",
      "resend": "Resend Unlock Instructions",
      "success": "Instructions have been sent to your email.",
      "unlock": "Unlock Account",
      "unlocked": "Your account has been unlocked."
    },
    "user": {
      "changePortal": "Change Client",
      "code": "Code",
      "email": "Email",
      "firstName": "First Name",
      "lastName": "Last Name",
      "logout": "Logout",
      "myAccount": "Profile",
      "twoFactorAuth": "Two Factor Authentication"
    }
  }]];
});