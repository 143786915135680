define('@fortawesome/pro-light-svg-icons', ['exports'], (function (exports) { 'use strict';

  const faRightFromBracket = {
    prefix: 'fal',
    iconName: 'right-from-bracket',
    icon: [512, 512, ["sign-out-alt"], "f2f5", "M479.2 254.2c.5 .5 .8 1.1 .8 1.8s-.3 1.4-.8 1.8L347.3 382.2c-1.2 1.2-2.9 1.8-4.6 1.8c-3.7 0-6.7-3-6.7-6.7l0-57.3c0-8.8-7.2-16-16-16l-120 0c-4.4 0-8-3.6-8-8l0-80c0-4.4 3.6-8 8-8l120 0c8.8 0 16-7.2 16-16l0-57.3c0-3.7 3-6.7 6.7-6.7c1.7 0 3.3 .7 4.6 1.8L479.2 254.2zM512 256c0-9.5-3.9-18.6-10.8-25.1L369.2 106.5C362 99.8 352.5 96 342.7 96c-21.4 0-38.7 17.3-38.7 38.7l0 41.3-104 0c-22.1 0-40 17.9-40 40l0 80c0 22.1 17.9 40 40 40l104 0 0 41.3c0 21.4 17.3 38.7 38.7 38.7c9.9 0 19.4-3.8 26.5-10.5L501.2 281.1c6.9-6.5 10.8-15.6 10.8-25.1zM176 64c8.8 0 16-7.2 16-16s-7.2-16-16-16L80 32C35.8 32 0 67.8 0 112L0 400c0 44.2 35.8 80 80 80l96 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-96 0c-26.5 0-48-21.5-48-48l0-288c0-26.5 21.5-48 48-48l96 0z"]
  };
  const faBars = {
    prefix: 'fal',
    iconName: 'bars',
    icon: [448, 512, ["navicon"], "f0c9", "M0 80c0-8.8 7.2-16 16-16l416 0c8.8 0 16 7.2 16 16s-7.2 16-16 16L16 96C7.2 96 0 88.8 0 80zM0 240c0-8.8 7.2-16 16-16l416 0c8.8 0 16 7.2 16 16s-7.2 16-16 16L16 256c-8.8 0-16-7.2-16-16zM448 400c0 8.8-7.2 16-16 16L16 416c-8.8 0-16-7.2-16-16s7.2-16 16-16l416 0c8.8 0 16 7.2 16 16z"]
  };
  const faLock = {
    prefix: 'fal',
    iconName: 'lock',
    icon: [448, 512, [128274], "f023", "M128 128l0 64 192 0 0-64c0-53-43-96-96-96s-96 43-96 96zM96 192l0-64C96 57.3 153.3 0 224 0s128 57.3 128 128l0 64 16 0c44.2 0 80 35.8 80 80l0 160c0 44.2-35.8 80-80 80L80 512c-44.2 0-80-35.8-80-80L0 272c0-44.2 35.8-80 80-80l16 0zM32 272l0 160c0 26.5 21.5 48 48 48l288 0c26.5 0 48-21.5 48-48l0-160c0-26.5-21.5-48-48-48L80 224c-26.5 0-48 21.5-48 48z"]
  };
  const faUser = {
    prefix: 'fal',
    iconName: 'user',
    icon: [448, 512, [128100, 62144], "f007", "M320 128a96 96 0 1 0 -192 0 96 96 0 1 0 192 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM32 480l384 0c-1.2-79.7-66.2-144-146.3-144l-91.4 0c-80 0-145 64.3-146.3 144zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3z"]
  };
  const faPersonToPortal = {
    prefix: 'fal',
    iconName: 'person-to-portal',
    icon: [512, 512, ["portal-enter"], "e022", "M256 56a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm80 0A56 56 0 1 0 224 56a56 56 0 1 0 112 0zM179.4 116.4c-12.7-3.2-26.1-3.2-38.8 0l-1.5 .4c-12.3 3.1-23.7 9-33.3 17.4L53.5 180c-6.7 5.8-7.3 15.9-1.5 22.6s15.9 7.3 22.6 1.5l52.4-45.8c5.7-5 12.6-8.6 20-10.4l1.5-.4c7.6-1.9 15.6-1.9 23.3 0l16.9 4.2L153.2 257.9c-7.3 21.8 2 45.7 22 57l93.3 52.5L224.9 490.7c-2.9 8.3 1.4 17.5 9.8 20.4s17.5-1.4 20.4-9.8L298.6 378c5.2-14.7-.9-30.9-14.5-38.5l-37.5-21.1c.2-.4 .4-.9 .5-1.3l37.2-111.6L298 253.2c5.9 20.6 24.7 34.8 46.2 34.8l55.9 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-55.9 0c-7.1 0-13.4-4.7-15.4-11.6l-20.3-71c-4.7-16.5-17.9-29.2-34.5-33.4l-94.5-23.6zm83 53.7L218.3 302.4 190.9 287c-6.7-3.8-9.8-11.7-7.3-19l36.2-108.6 42.7 10.7zM130 314.1L101.3 384 16 384c-8.8 0-16 7.2-16 16s7.2 16 16 16l85.3 0c13 0 24.7-7.8 29.6-19.8l23.3-56.6c-10-6.7-18.2-15.5-24.1-25.4zM458.5 444.2C445.6 480 433.6 480 432 480c0 0 0 0 0 0s0 0 0 0c-1.6 0-13.6 0-26.4-35.8c-9.4-26.3-16.8-66.4-19.9-124.2l-32 0c7.3 140.7 39.7 192 78.4 192c44.2 0 80-66.6 80-256S476.2 0 432 0c-41.6 0-75.8 59-79.6 224l32 0c1.8-75.3 10.1-125.3 21.2-156.2C418.4 32 430.4 32 432 32c0 0 0 0 0 0s0 0 0 0c1.6 0 13.6 0 26.4 35.8C471.1 102.9 480 162.5 480 256s-8.9 153.1-21.5 188.2z"]
  };
  const faChartNetwork = {
    prefix: 'fal',
    iconName: 'chart-network',
    icon: [640, 512, [], "f78a", "M224 32a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm0 96c4.5 0 8.9-.5 13.1-1.3l38.1 83.2c-26.6 17.2-45.4 45.4-50.1 78.1L126 288c-7.1-27.6-32.2-48-62-48c-35.3 0-64 28.7-64 64s28.7 64 64 64c29.8 0 54.9-20.4 62-48l99.2 0c7.8 54.3 54.4 96 110.9 96c35 0 66.3-16.1 86.8-41.2l91.8 55.1c-1.7 5.8-2.6 11.9-2.6 18.2c0 35.3 28.7 64 64 64s64-28.7 64-64s-28.7-64-64-64c-17.5 0-33.4 7-44.9 18.4l-91.8-55.1c5.6-13.3 8.7-28 8.7-43.3c0-18.7-4.6-36.3-12.7-51.8l101.8-73.3c10.8 8.2 24.2 13.1 38.8 13.1c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64c0 9.1 1.9 17.8 5.4 25.7L416.6 226.2c-20.4-21.1-49-34.2-80.6-34.2c-11 0-21.6 1.6-31.7 4.5l-38.5-84.1C279.4 100.7 288 83.4 288 64c0-35.3-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64zM576 96a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM544 448a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM64 272a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm272-48a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"]
  };
  const faLockKeyhole = {
    prefix: 'fal',
    iconName: 'lock-keyhole',
    icon: [448, 512, ["lock-alt"], "f30d", "M224 32c53 0 96 43 96 96l0 64-192 0 0-64c0-53 43-96 96-96zM96 128l0 64-16 0c-44.2 0-80 35.8-80 80L0 432c0 44.2 35.8 80 80 80l288 0c44.2 0 80-35.8 80-80l0-160c0-44.2-35.8-80-80-80l-16 0 0-64C352 57.3 294.7 0 224 0S96 57.3 96 128zM80 224l288 0c26.5 0 48 21.5 48 48l0 160c0 26.5-21.5 48-48 48L80 480c-26.5 0-48-21.5-48-48l0-160c0-26.5 21.5-48 48-48zm160 88c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 80c0 8.8 7.2 16 16 16s16-7.2 16-16l0-80z"]
  };
  const faEye = {
    prefix: 'fal',
    iconName: 'eye',
    icon: [576, 512, [128065], "f06e", "M117.2 136C160.3 96 217.6 64 288 64s127.7 32 170.8 72c43.1 40 71.9 88 85.2 120c-13.3 32-42.1 80-85.2 120c-43.1 40-100.4 72-170.8 72s-127.7-32-170.8-72C74.1 336 45.3 288 32 256c13.3-32 42.1-80 85.2-120zM288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM192 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0a128 128 0 1 0 -256 0 128 128 0 1 0 256 0z"]
  };
  const faEnvelope = {
    prefix: 'fal',
    iconName: 'envelope',
    icon: [512, 512, [128386, 9993, 61443], "f0e0", "M64 96c-17.7 0-32 14.3-32 32l0 39.9L227.6 311.3c16.9 12.4 39.9 12.4 56.8 0L480 167.9l0-39.9c0-17.7-14.3-32-32-32L64 96zM32 207.6L32 384c0 17.7 14.3 32 32 32l384 0c17.7 0 32-14.3 32-32l0-176.4L303.3 337.1c-28.2 20.6-66.5 20.6-94.6 0L32 207.6zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z"]
  };
  const faMoneyCheckDollarPen = {
    prefix: 'fal',
    iconName: 'money-check-dollar-pen',
    icon: [640, 512, ["money-check-edit-alt"], "f873", "M512 96L64 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l274.2 0-8 32L64 448c-35.3 0-64-28.7-64-64L0 128C0 92.7 28.7 64 64 64l448 0c35.3 0 64 28.7 64 64l0 64c-10.9-.3-21.8 1.5-32 5.6l0-69.6c0-17.7-14.3-32-32-32zM256 304c0-8.8 7.2-16 16-16l96 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-96 0c-8.8 0-16-7.2-16-16zm16-112l192 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-192 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zM144 144c8.8 0 16 7.2 16 16l0 9.6c5.9 1 19 3.5 24.1 4.9c8.5 2.3 13.6 11 11.3 19.6s-11 13.6-19.6 11.3c-4-1.1-17.7-3.7-22.1-4.4c-12.8-2-23.6-.4-30.9 2.9c-7.2 3.3-9.9 7.5-10.5 11c-.7 4.1-.1 6.2 .5 7.4c.6 1.4 2 3.1 5 5.1c6.7 4.6 17.1 7.8 31 12l.6 .2s0 0 0 0c12.3 3.7 27.9 8.4 39.5 16.9c6.3 4.5 12 10.6 15.6 18.8c3.6 8.3 4.4 17.4 2.7 27.2c-3 17.5-14.6 29.4-29.3 35.7c-5.6 2.4-11.6 4-17.9 4.9l0 8.9c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-9.6c-9.4-1.7-23.5-6.6-32.4-9.6c0 0 0 0 0 0s0 0 0 0s0 0 0 0s0 0 0 0c-1.8-.6-3.3-1.1-4.6-1.6c-8.4-2.8-12.9-11.9-10.1-20.2s11.9-12.9 20.2-10.1c1.9 .6 4 1.3 6.1 2.1c0 0 0 0 0 0s0 0 0 0c9.5 3.2 20.7 7 27.1 8.1c13.3 2.1 24 .8 31-2.2c6.5-2.8 9.5-6.7 10.3-11.8c.8-4.7 .2-7.3-.5-9c-.7-1.7-2.2-3.6-5.1-5.7c-6.5-4.8-16.7-8.1-30.5-12.3l-2-.6s0 0 0 0c-11.9-3.6-26.6-8-37.7-15.5c-6.2-4.2-12.2-10-15.9-17.9c-3.8-8.1-4.7-17.1-3.1-26.6c2.9-16.7 14.7-28.4 28.8-34.7c5.6-2.6 11.8-4.4 18.3-5.4l0-9.3c0-8.8 7.2-16 16-16zM582.8 264c-6.2-6.2-16.4-6.2-22.6 0l-24.8 24.8 40 40L600.2 304c6.2-6.2 6.2-16.4 0-22.6L582.8 264zM406.5 417.7c-2.1 2.1-3.5 4.6-4.2 7.4l-12.3 49 49-12.3c2.8-.7 5.4-2.2 7.4-4.2L552.7 351.4l-40-40L406.5 417.7zM537.5 241.4c18.7-18.7 49.1-18.7 67.9 0l17.4 17.4c18.7 18.7 18.7 49.1 0 67.9L469.1 480.3c-6.2 6.2-13.9 10.5-22.3 12.6l-74.9 18.7c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l18.7-74.9c2.1-8.4 6.5-16.1 12.6-22.3L537.5 241.4z"]
  };
  const faEllipsis = {
    prefix: 'fal',
    iconName: 'ellipsis',
    icon: [448, 512, ["ellipsis-h"], "f141", "M416 256a32 32 0 1 1 -64 0 32 32 0 1 1 64 0zm-160 0a32 32 0 1 1 -64 0 32 32 0 1 1 64 0zM64 288a32 32 0 1 1 0-64 32 32 0 1 1 0 64z"]
  };
  const faListCheck = {
    prefix: 'fal',
    iconName: 'list-check',
    icon: [512, 512, ["tasks"], "f0ae", "M156.3 58.2c5.7-6.8 4.7-16.9-2-22.5s-16.9-4.7-22.5 2L62.9 120.3 27.3 84.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l48 48c3.2 3.2 7.5 4.9 12 4.7s8.7-2.3 11.6-5.7l80-96zm0 160c5.7-6.8 4.7-16.9-2-22.5s-16.9-4.7-22.5 2L62.9 280.3 27.3 244.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l48 48c3.2 3.2 7.5 4.9 12 4.7s8.7-2.3 11.6-5.7l80-96zM192 96c0 8.8 7.2 16 16 16l288 0c8.8 0 16-7.2 16-16s-7.2-16-16-16L208 80c-8.8 0-16 7.2-16 16zm0 160c0 8.8 7.2 16 16 16l288 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-288 0c-8.8 0-16 7.2-16 16zM160 416c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-320 0c-8.8 0-16 7.2-16 16zm-64 0a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"]
  };
  const faCircleUser = {
    prefix: 'fal',
    iconName: 'circle-user',
    icon: [512, 512, [62142, "user-circle"], "f2bd", "M412.1 416.6C398.1 361.1 347.9 320 288 320l-64 0c-59.9 0-110.1 41.1-124.1 96.6C58 375.9 32 319 32 256C32 132.3 132.3 32 256 32s224 100.3 224 224c0 63-26 119.9-67.9 160.6zm-28.5 23.4C347.5 465.2 303.5 480 256 480s-91.5-14.8-127.7-39.9c4-49.3 45.3-88.1 95.7-88.1l64 0c50.4 0 91.6 38.8 95.7 88.1zM256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-256a48 48 0 1 1 0-96 48 48 0 1 1 0 96zm-80-48a80 80 0 1 0 160 0 80 80 0 1 0 -160 0z"]
  };
  const faSpinner = {
    prefix: 'fal',
    iconName: 'spinner',
    icon: [512, 512, [], "f110", "M256 64a16 16 0 1 0 0-32 16 16 0 1 0 0 32zm0-64a48 48 0 1 1 0 96 48 48 0 1 1 0-96zm0 480a16 16 0 1 0 0-32 16 16 0 1 0 0 32zm0-64a48 48 0 1 1 0 96 48 48 0 1 1 0-96zM64 256a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zM0 256a48 48 0 1 1 96 0A48 48 0 1 1 0 256zm464 16a16 16 0 1 0 0-32 16 16 0 1 0 0 32zm0-64a48 48 0 1 1 0 96 48 48 0 1 1 0-96zM120.2 391.8A16 16 0 1 0 97.6 414.4a16 16 0 1 0 22.6-22.6zM75 437a48 48 0 1 1 67.9-67.9A48 48 0 1 1 75 437zM97.6 120.2a16 16 0 1 0 22.6-22.6A16 16 0 1 0 97.6 120.2zM142.9 75A48 48 0 1 1 75 142.9 48 48 0 1 1 142.9 75zM414.4 414.4a16 16 0 1 0 -22.6-22.6 16 16 0 1 0 22.6 22.6zm-45.3-45.3A48 48 0 1 1 437 437a48 48 0 1 1 -67.9-67.9z"]
  };
  const faBuilding = {
    prefix: 'fal',
    iconName: 'building',
    icon: [384, 512, [127970, 61687], "f1ad", "M64 32C46.3 32 32 46.3 32 64l0 384c0 17.7 14.3 32 32 32l64 0 0-64c0-35.3 28.7-64 64-64s64 28.7 64 64l0 64 64 0c17.7 0 32-14.3 32-32l0-384c0-17.7-14.3-32-32-32L64 32zM224 416c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 64 64 0 0-64zm-96 96l-64 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L320 0c35.3 0 64 28.7 64 64l0 384c0 35.3-28.7 64-64 64l-64 0-32 0-64 0-32 0zM64 120c0-13.3 10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 48c0 13.3-10.7 24-24 24l-48 0c-13.3 0-24-10.7-24-24l0-48zm32 8l0 32 32 0 0-32-32 0zM248 96l48 0c13.3 0 24 10.7 24 24l0 48c0 13.3-10.7 24-24 24l-48 0c-13.3 0-24-10.7-24-24l0-48c0-13.3 10.7-24 24-24zm8 64l32 0 0-32-32 0 0 32zM64 248c0-13.3 10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 48c0 13.3-10.7 24-24 24l-48 0c-13.3 0-24-10.7-24-24l0-48zm32 8l0 32 32 0 0-32-32 0zm152-32l48 0c13.3 0 24 10.7 24 24l0 48c0 13.3-10.7 24-24 24l-48 0c-13.3 0-24-10.7-24-24l0-48c0-13.3 10.7-24 24-24zm8 64l32 0 0-32-32 0 0 32z"]
  };
  const faShield = {
    prefix: 'fal',
    iconName: 'shield',
    icon: [512, 512, [128737, "shield-blank"], "f132", "M268.5 37.3c-8-3.4-17-3.4-25 0l-176.7 75C55.5 117 47.9 127.7 48 139.8c.5 94 39.3 259.8 195.4 334.5c7.9 3.8 17.2 3.8 25.1 0c156.1-74.7 195-240.4 195.5-334.5c.1-12.1-7.5-22.8-18.8-27.6l-176.7-75zM231 7.8c16-6.8 34-6.8 50 0l176.7 75c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L231 7.8z"]
  };
  const faFileInvoice = {
    prefix: 'fal',
    iconName: 'file-invoice',
    icon: [384, 512, [], "f570", "M320 480L64 480c-17.7 0-32-14.3-32-32L32 64c0-17.7 14.3-32 32-32l128 0 0 112c0 26.5 21.5 48 48 48l112 0 0 256c0 17.7-14.3 32-32 32zM240 160c-8.8 0-16-7.2-16-16l0-111.5c2.8 .7 5.4 2.1 7.4 4.2L347.3 152.6c2.1 2.1 3.5 4.6 4.2 7.4L240 160zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-284.1c0-12.7-5.1-24.9-14.1-33.9L254.1 14.1c-9-9-21.2-14.1-33.9-14.1L64 0zm0 80c0 8.8 7.2 16 16 16l64 0c8.8 0 16-7.2 16-16s-7.2-16-16-16L80 64c-8.8 0-16 7.2-16 16zm0 64c0 8.8 7.2 16 16 16l64 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-64 0c-8.8 0-16 7.2-16 16zM224 432c0 8.8 7.2 16 16 16l64 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-64 0c-8.8 0-16 7.2-16 16zm64-96L96 336l0-64 192 0 0 64zM96 240c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l192 0c17.7 0 32-14.3 32-32l0-64c0-17.7-14.3-32-32-32L96 240z"]
  };

  exports.faBars = faBars;
  exports.faBuilding = faBuilding;
  exports.faChartNetwork = faChartNetwork;
  exports.faCircleUser = faCircleUser;
  exports.faEllipsis = faEllipsis;
  exports.faEnvelope = faEnvelope;
  exports.faEye = faEye;
  exports.faFileInvoice = faFileInvoice;
  exports.faListCheck = faListCheck;
  exports.faLock = faLock;
  exports.faLockKeyhole = faLockKeyhole;
  exports.faMoneyCheckDollarPen = faMoneyCheckDollarPen;
  exports.faPersonToPortal = faPersonToPortal;
  exports.faRightFromBracket = faRightFromBracket;
  exports.faShield = faShield;
  exports.faSpinner = faSpinner;
  exports.faUser = faUser;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
